var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logon login-form"},[_c('h1',[_vm._v("注册")]),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"status-icon":"","rules":_vm.rules,"label-width":"100px","label-position":"left"}},[_c('el-form-item',{attrs:{"prop":"nick_name","rules":[
            { required: true, message: '昵称不能为空'},
            { type: 'string', required: true, min: 3, message: '昵称大于3个字符'}
          ]}},[_c('el-input',{attrs:{"type":"nick_name","autocomplete":"off","placeholder":"昵称"},model:{value:(_vm.ruleForm.nick_name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "nick_name", $$v)},expression:"ruleForm.nick_name"}})],1),_c('el-form-item',{attrs:{"prop":"login","rules":[
            { required: true, message: '账号不能为空'},
            { type: 'string', required: true, min: 3, message: '账号大于3个字符'}
          ]}},[_c('el-input',{attrs:{"type":"login","autocomplete":"off","placeholder":"账号"},model:{value:(_vm.ruleForm.login),callback:function ($$v) {_vm.$set(_vm.ruleForm, "login", $$v)},expression:"ruleForm.login"}})],1),_c('el-form-item',{attrs:{"prop":"password","rules":[
            { required: true, message: '密码不能为空'},
            { type: 'string', required: true, min: 6, message: '密码大于6个字符'}

          ]}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"密码"},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}})],1),_c('el-form-item',{attrs:{"prop":"confirm_password","rules":[
            { required: true, message: '密码不能为空'},
            { type: 'string', required: true, min: 6, message: '密码大于6个字符'}

          ]}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"确认密码"},model:{value:(_vm.ruleForm.confirm_password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "confirm_password", $$v)},expression:"ruleForm.confirm_password"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"but",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }